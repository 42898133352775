import React from "react";
import { FaRocket, FaPlug, FaRegClock } from "react-icons/fa"; // Import icons from React Icons

const Features = () => {
  const features = [
    {
      title: "Efficient Lead Generation",
      description:
        "Supercharge your sales pipeline with AI tools that deliver high-quality leads, saving you time and boosting revenue.",
      icon: <FaRocket className="text-yellow-500 text-4xl" />,
    },
    {
      title: "24/7 Support",
      description:
        " Provide round-the-clock assistance to your clients with AI-driven support systems that ensure no query goes unanswered.",
      icon: <FaRegClock className="text-yellow-500 text-4xl" />,
    },
  ];

  return (
    <div className="p-5 pb-12 bg-[rgb(1,4,14)] flex justify-center">
      {/* Content Wrapper */}
      <div className="w-full max-w-6xl">
        {/* Title */}
        <h1 className="font-poppins font-semibold text-4xl sm:text-5xl lg:text-6xl text-white leading-tight text-center mb-12">
          What We Offer
        </h1>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-12">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center text-center p-10 h-auto rounded-xl shadow-lg bg-[#1e1b2d] border border-transparent hover:border-yellow-500 hover:bg-[#252033] transition-transform transform hover:-translate-y-2 duration-300 w-full max-w-[600px] mx-auto"
            >
              <div className="mb-6">{feature.icon}</div>
              <h3 className="text-white font-extrabold text-2xl lg:text-3xl mb-4">
                {feature.title}
              </h3>
              <p className="text-gray-300 text-lg lg:text-xl leading-relaxed">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Single Box Below */}
        <div className="mt-12 flex justify-center">
          <div className="flex flex-col items-center justify-center text-center p-10 h-auto rounded-xl shadow-lg bg-[#1e1b2d] border border-transparent hover:border-yellow-500 hover:bg-[#252033] transition-transform transform hover:-translate-y-2 duration-300 w-full max-w-[600px]">
            <div className="mb-6">
              <FaPlug className="text-yellow-500 text-4xl" />
            </div>
            <h3 className="text-white font-extrabold text-2xl lg:text-3xl mb-4">
              Seamless Automations
            </h3>
            <p className="text-gray-300 text-lg lg:text-xl leading-relaxed">
            Streamline  workflows by automating repetitive tasks, improving efficiency, and reducing manual effort with our custom solutions.

             
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
